import React, { useState } from 'react';
import { IconBrandWechat, IconMessageReport, IconBell, IconUserCircle, IconSignRight } from '@tabler/icons-react';
import { Typography, Badge, IconButton, useMediaQuery, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Avatar from 'components/@common/Avatar';
import { IconLogout2, IconKey, IconUserCog } from '@tabler/icons-react';
import messages from './messages';
import ChangePasswordDialog from './ChangePasswordDialog';
import paths from 'utils/constants/paths';
import { imagePathResolver } from 'utils/imageResolvers';
import { useGetUserInfoQuery } from 'store/rtk-query/userApi';
import { MenuIconComponent } from 'components/@common';
import useLogout from 'hooks/useLogout';
import { useGetOrganizationInfoQuery } from 'store/rtk-query/organizationApi';
import { isRolePermitted } from 'utils/checkUserRoles';
import { ROLES } from 'utils/constants/roles';
import useLocalStorage from 'hooks/useLocalStorage';
import globalConstant from 'utils/constants/globalConstant';
import { useMessagesCountQuery } from 'store/rtk-query/chatApi';
import { dispatch } from 'store';
import { setTourState } from 'store/reducers/tourGuideReducer';

const HeaderIconComponent = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [openPasswordChange, setOpenPasswordChange] = useState(false);
  const { data: userData } = useGetUserInfoQuery();
  const navigate = useNavigate();

  const organizationUserId = userData?.organization?.uuid;
  const { palette } = useTheme();
  const { data: organizationInfo } = useGetOrganizationInfoQuery(organizationUserId, { skip: !organizationUserId });
  const handleLogout = useLogout();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(() => null);
  };

  const [jwtTokenValue] = useLocalStorage(globalConstant.JWT_TOKEN, '');
  const { data: { newMessageCount: messagesCount = 0 } = {} } = useMessagesCountQuery(undefined, { skip: !jwtTokenValue });

  const headerIcon = [
    {
      id: 0,
      isDisable: false,
      Component: IconBrandWechat,
      label: intl.formatMessage(messages.chat),
      className: 'chat',
      onClick: () => {
        navigate('/chat');
      },
      isBadge: true
    },
    {
      id: 1,
      isDisable: false,
      Component: IconMessageReport,
      label: intl.formatMessage(messages.feedback),
      className: 'header-feedback',
      onClick: () => {
        window.open(globalConstant.FEEDBACK_FORM_LINK, '_blank');
      },
      isBadge: false
    },
    { id: 2, isDisable: true, Component: IconBell, label: intl.formatMessage(messages.notification), onClick: () => {}, isBadge: false },
    {
      id: 3,
      isDisable: false,
      Component: IconUserCircle,
      isImageAvatar: true,
      label: intl.formatMessage(messages.you),
      onClick: () => isMobile && navigate(paths.Profile.url),
      isBadge: false
    }
  ];

  const PopOverContent = [
    {
      avatarSrc: imagePathResolver(userData?.profileImage),
      id: 1,
      title: intl.formatMessage(messages.showProfile),
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        navigate(paths.Profile.url);
      }
    },
    isRolePermitted(userData?.roles, ROLES.ADMIN) && {
      avatarSrc: imagePathResolver(organizationInfo?.profileImage),
      id: 2,
      title: intl.formatMessage(messages.licenseAdministration),
      className: 'license-administration',
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        navigate(paths.LicenseAdministration.url);
      }
    },
    {
      icon: <IconKey style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} stroke={1.3} />,
      id: 3,
      title: intl.formatMessage(messages.changePassword),
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        setOpenPasswordChange(true);
      }
    },
    isRolePermitted(userData?.roles, ROLES.SUPER_ADMIN) && {
      id: 4,
      icon: <IconUserCog style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} stroke={1.3} />,
      title: intl.formatMessage(messages.SuperAdminPanel),
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        navigate(paths.SuperAdmin.url);
      }
    },
    {
      icon: <IconSignRight style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} stroke={1.3} />,
      id: 5,
      title: intl.formatMessage(messages.appTourGuide),
      onClick: (event) => {
        event.stopPropagation();
        handleClose();
        dispatch(
          setTourState({
            type: 'RESTART',
            stepKeys: pathname
          })
        );
      }
    },
    {
      icon: <IconLogout2 style={{ color: palette.primary.main, marginRight: '0.5rem', marginLeft: '0.5rem' }} stroke={1.3} />,
      id: 6,
      title: intl.formatMessage(messages.logout),
      onClick: handleLogout
    }
  ].filter(Boolean);

  return (
    <>
      {headerIcon.map(({ id, Component, label, onClick, isBadge = false, isDisable, isImageAvatar, className }) => (
        <IconButton
          key={id}
          gap={0.25}
          sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', borderRadius: '5px', padding: '5px', alignItems: 'center' }}
          onClick={onClick}
          disabled={isDisable}
          className={className}
        >
          {isImageAvatar ? (
            <MenuIconComponent
              options={!isMobile && PopOverContent}
              anchorEl={anchorEl}
              open={open}
              handleClick={handleClick}
              handleClose={handleClose}
              customAnchorPositionTop={52}
              customAnchorPositionLeft={35}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                '& .MuiButtonBase-root': {
                  padding: 0
                }
              }}
              renderIcon={
                <Stack className={'header-you'}>
                  <Avatar sx={{ width: 30, height: 30 }} src={imagePathResolver(userData?.profileImage)} alt="user-profile" />
                  <Typography
                    sx={{ fontSize: '10px', color: (theme) => theme.palette.secondary[600], textTransform: 'capitalize', fontWeight: 400 }}
                  >
                    {label}
                  </Typography>
                </Stack>
              }
            />
          ) : !isBadge ? (
            <Component color={isDisable ? palette.grey[500] : palette.primary.main} size={downLG ? 25 : 30} stroke={downLG ? 1.5 : 1.2} />
          ) : (
            <>
              <Badge color="primary" sx={{ left: 9, top: 4 }} badgeContent={messagesCount > 0 ? messagesCount : 0} />
              <Component color={isDisable ? palette.grey[500] : palette.primary.main} size={downLG ? 25 : 30} stroke={downLG ? 1.5 : 1.2} />
            </>
          )}
          {!isImageAvatar && (
            <Typography
              sx={{ fontSize: '10px', color: (theme) => theme.palette.secondary[600], textTransform: 'capitalize', fontWeight: 400 }}
            >
              {label}
            </Typography>
          )}
        </IconButton>
      ))}
      <ChangePasswordDialog setOpenPasswordChange={setOpenPasswordChange} openPasswordChange={openPasswordChange} />
    </>
  );
};

export default HeaderIconComponent;
