import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogContentText,
  Typography
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { IconSquareRoundedX } from '@tabler/icons-react';
import { LoadingButton } from '@mui/lab';
import { PopupTransition } from './Transitions';

const Dialog = ({
  isOpen,
  handleClose,
  title,
  content,
  okBtnText,
  cancelBtnText,
  okSubmitHandle,
  cancelSubmitHandle,
  children,
  loading = false,
  isOkBtnDisable,
  dialogSx
}) => {
  return (
    <MuiDialog
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={PopupTransition}
      aria-labelledby="dialog compoent"
      aria-describedby="show dialog component"
      sx={{ ...dialogSx }}
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'primary.main', textTransform: 'capitalize' }}
      >
        <Typography fontWeight={500}>{title}</Typography>
        <IconButton onClick={handleClose} sx={{ color: 'primary.mainLight', mr: '-8px' }}>
          {handleClose && <IconSquareRoundedX />}
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: '1rem !important' }}>
        {content && <DialogContentText sx={{ mb: 2 }}>{content}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions sx={{ px: '1.5rem', pb: '1.5rem' }}>
        {!!cancelBtnText && (
          <Button
            color="error"
            variant="outlined"
            onClick={cancelSubmitHandle}
            sx={{
              width: '10rem',
              height: '2rem',
              borderRadius: '6px'
            }}
          >
            {cancelBtnText}
          </Button>
        )}
        {!!okBtnText && (
          <LoadingButton
            loading={loading}
            disabled={isOkBtnDisable}
            variant="contained"
            onClick={okSubmitHandle}
            sx={{
              // width: '12rem',
              height: '2rem',
              borderRadius: '6px',
              ':hover': { border: '1px solid grey', bgcolor: 'white', color: 'primary.main' }
            }}
          >
            {okBtnText}
          </LoadingButton>
        )}
      </DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.any,
  okBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  okSubmitHandle: PropTypes.func,
  cancelSubmitHandle: PropTypes.func,
  children: PropTypes.node,
  loading: PropTypes.bool,
  isOkBtnDisable: PropTypes.bool,
  dialogSx: PropTypes.object
};

export default Dialog;
