const paths = {
  Root: {
    url: '/'
  },
  MyInnofuse: {
    url: '/my-innofuse',
    pathTitle: 'My Innofuse'
  },
  Reactor: {
    url: '/reactor',
    pathTitle: 'Reactor',
    Search: {
      url: '/reactor/search',
      pathTitle: 'Search'
    }
  },
  Network: {
    url: '/network',
    pathTitle: 'Networking Hub'
  },
  NetworkDetails: {
    url: '/network/:userId/:networkType',
    pathTitle: 'Network Details'
  },
  NetworkTabs: {
    url: '/network/:userId/:networkType/:tabValue',
    pathTitle: 'Network Details'
  },
  Initiatives: {
    url: '/initiatives',
    pathTitle: 'Initiatives Hub'
  },
  InitiativesTabs: {
    url: '/initiatives/:initiativeId/:tabValue',
    pathTitle: 'Initiatives Hub'
  },
  AddInitiatives: {
    url: '/initiatives/add',
    pathTitle: 'Add Initiative'
  },
  EditInitiatives: {
    url: '/initiatives/edit/:initiativeId',
    pathTitle: 'Edit Initiative'
  },
  SelectInitiative: {
    url: '/initiatives/select',
    pathTitle: 'Select Initiative'
  },
  InitiativesDetail: {
    url: '/initiatives/:initiativeId',
    pathTitle: 'Initiatives Overview',
    ContributionsDetail: {
      url: 'initiatives/:initiativeId/contributions/:newsId'
    },
    LinkedInitiativeDetail: {
      url: 'initiatives/:initiativeId/linked-initiative/list'
    },
    AddContributions: {
      url: 'initiatives/:initiativeId/contributions/add'
    },
    EditContributions: {
      url: 'initiatives/:initiativeId/contributions/:newsId/edit'
    },
    Invites: {
      url: 'initiative/:initiativeId/invitee/:inviteeId'
    },
    AdminInvites: {
      url: 'initiative/:initiativeId/administrator/:administratorId/:token'
    },
    JoinInitiatives: {
      url: '/initiative/:initiativeId/door-knock/:doorKnockId'
    }
  },
  Trainings: {
    url: '/trainings',
    pathTitle: 'Trainings'
  },
  ContactUs: {
    url: '/contact-us',
    pathTitle: 'Contact Us'
  },
  TermsAndPolicies: {
    url: '/contact-us',
    pathTitle: 'Terms & Policies'
  },
  Services: {
    url: '/services',
    pathTitle: 'Services'
  },
  Profile: {
    url: '/profile'
  },
  LicenseAdministration: {
    url: '/license-administration'
  },
  Login: {
    url: '/login'
  },
  SetPassword: {
    url: '/set-password/:id',
    SetNewPassWord: {
      url: 'set-new-password/:id'
    }
  },
  SendEmail: {
    url: 'forget-password'
  },
  OrganizationUserRegistration: {
    url: '/organization/user-registration'
  },
  Chats: {
    url: '/chat',
    UserChats: {
      url: '/chat/:id'
    }
  },
  SuperAdmin: {
    url: '/super-admin'
  },
  SuperAdminWithTab: {
    url: '/super-admin/:tabValue'
  }
};

export default paths;
