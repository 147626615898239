import PropTypes from 'prop-types';

// material-ui
import { Box, CircularProgress, Typography } from '@mui/material';

// ==============================|| PROGRESS - CIRCULAR LABEL ||============================== //

export default function CircularWithLabel({ label }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        m: 'auto'
      }}
      position="absolute"
    >
      <CircularProgress />
      <Typography variant="caption" component="label" color="text.secondary">
        {label}
      </Typography>
    </Box>
  );
}

export function CircularLodder() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress />
    </Box>
  );
}

CircularWithLabel.propTypes = {
  label: PropTypes.string
};
